<template>
  <v-app id="agents" class="overflow-hidden mt-n1 mt-md-n1  px-md-4">
    <v-container px-4 class="pt-10">
      <h1 class="headline font-weight-bold">Agents</h1>
      <p class="my-3">
        Enhance your real estate property search in Nigeria by using our online
        property listing platform to connect with our best reals estate
        Developers, Agencies and agent list specialized in residential and
        commercial real estate for sale, rent or lease in Nigeria.
      </p>
      <p>
        Collaborate with the best real estate agents to provide you all the good
        property finds that you’ll need. Find a real estate Nigeria now and get
        in touch with the best real estate company in Nigeria today.
      </p>
      <h3 class="subtitle font-weight-medium mt-9 mb-6">Real Estate Agents</h3>
      <v-row>
        <v-col cols="12" md="9">
          <AgentsFilterMobile />
          <div v-if="skeloader || processedAgents == false">
            <skeleton-loader/>
          </div>
          <v-row v-if="skeloader == false">
            <v-col md="4" v-for="(item, i) in processedAgents" v-bind:key="i">
              <v-card class="mx-auto" max-width="400" flat>
                <router-link
                  class="text-decoration-none"
                  :to="{ path: `/agent/products/${item.id}` }"
                >
                  <v-img
                    class="white--text align-end"
                    height="200px"
                    :src="
                      item.image
                        ? item.image
                        : require(`../../assets/avatar.png`)
                    "
                  >
                  </v-img>
                  <div class="mt-2 px-1">
                    <span class=" grey--text text--darken-4 ">
                      {{ item.agent.title ? item.agent.title : item.full_name }}
                    </span>
                    <v-icon
                      v-show="item.verify && item.verify.status == 1"
                      small
                      class="green--text"
                      >mdi-check-decagram</v-icon
                    >
                  </div>
                  <v-card-text class="mt-n3 text-justify" v-text="item.about"></v-card-text>
                </router-link>
                <v-card-actions>
                  <a
                    class="text-decoration-none"
                    :href="item.agent.facebook_url"
                  >
                    <v-btn depressed fab small class="justify-center">
                      <v-icon color="#837E91" size="22px">
                        mdi-facebook
                      </v-icon>
                    </v-btn>
                  </a>
                  <a
                    class="text-decoration-none"
                    :href="item.agent.instagram_url"
                  >
                    <v-btn depressed fab small class="justify-center">
                      <v-icon color="#837E91" size="22px">
                        mdi-instagram
                      </v-icon>
                    </v-btn>
                  </a>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="dialogMsg(item)"
                    depressed
                    fab
                    small
                    class="justify-center"
                  >
                    <v-icon color="#837E91" size="22px">
                      mdi-email
                    </v-icon>
                  </v-btn>

                  <v-dialog
                    v-model="dialogMessage"
                    max-width="500px"
                    persistent
                    :retain-focus="false"
                  >
                    <v-card class="pa-2">
                      <p>
                        Send Message to
                        <span class="font-weight-bold">{{ agentName }}</span>
                      </p>
                      <div class="text-center">
                        <v-textarea
                          outlined
                          class="mt-2"
                          label="type message here"
                          v-on:keyup.enter="sendMessageConfirm"
                          v-model="messageContent"
                        ></v-textarea>
                        <div class=" mt-n4">
                          <v-btn
                            v-show="loading == false"
                            color="green"
                            dark
                            block
                            @click="sendMessageConfirm"
                          >
                            Send
                          </v-btn>
                          <v-progress-circular
                            v-show="loading"
                            :width="3"
                            class="mb-4 text-center"
                            color="green"
                            indeterminate
                          >
                          </v-progress-circular>
                        </div>
                      </div>
                      <v-btn
                        block
                        outlined
                        class="mt-1 white--text"
                        color="grey darken-3"
                        @click="closeMessage"
                        >Cancel</v-btn
                      >
                    </v-card>
                  </v-dialog>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        fab
                        small
                        class="justify-center"
                      >
                        <v-icon color="#837E91" size="22px">
                          mdi-phone
                        </v-icon>
                      </v-btn>
                    </template>
                    <span class="white--text">{{ item.phone_number }}</span>
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <agent-page-control />
          <v-container v-if="productListLoading == true">
            <v-sheet class="pa-12" color="grey lighten-3">
              <v-sheet
                :elevation="elevation"
                class="mx-auto text-center"
                color="grey lighten-3"
                ><div>
                  No Agent Found
                </div></v-sheet
              >
            </v-sheet>
          </v-container>
        </v-col>
        <v-col cols="12" md="3" class="hidden-sm-and-down" color="white">
          <AgentsFilter />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import SkeletonLoader from "../layout/SkeletonLoader";
import AgentPageControl from "../layout/AgentPageControl";
import { mapActions, mapGetters, mapState } from "vuex";
import AgentsFilter from "../layout/AgentsFilter.vue";
import AgentsFilterMobile from "../layout/AgentsFilterMobile.vue";

export default {
  components: {
    AgentsFilter,
    AgentPageControl,
    AgentsFilterMobile,
    SkeletonLoader,
  },
  metaInfo() {
		return {
			title: `Property, Houses, Lands & Apartments Agents in Nigeria  | Housefinder.com.ng`,
			meta: [
				{
					vmid: "description",
					name: "description",
					content: `List of Flats for Sale in Nigeria, Real Estate Property Houses Apartments For Rent! best deals in Nigeria - Housefinder.com.ng`
				}
			]
		}
	},
  data: () => ({
    drawer: false,
    loading: false,
    agentName: "",
    agentId: "",
    selection: 1,
    dialog: false,
    messageContent: "",
    skeloader: true,
    dialogMessage: false,
    avater: require("../../assets/avatar.png"),
  }),
  computed: {
    ...mapGetters(["processedAgents"]),
    ...mapState(["productListLoading"]),
  },

  watch: {
    group() {
      this.drawer = false;
    },
    dialogMsg(val) {
      val || this.closeMessage();
    },
  },

  methods: {
    ...mapActions(["getAgent", "createChatSession", "sendChat"]),
    async getData() {
      this.skeloader = true;
      await this.getAgent();
      console.log(this.processedAgents);
      this.skeloader = false;
    },
    dialogMsg(user) {
      this.agentName = user.agent.title ? user.agent.title : user.full_name;
      this.agentId = user.id;
      this.dialogMessage = true;
    },
    async sendMessageConfirm() {
      event.preventDefault();
      let token = localStorage.getItem("token");
      let user = localStorage.getItem("authUser");
      let authUser = JSON.parse(user);
      if (token) {
        if (this.messageContent != "" && this.agentId != authUser.id) {
          this.loading = true;
          let friendId = { friend_id: this.agentId };
          let session_id = await this.createChatSession(friendId);
          if (session_id) {
            let data = {
              content: this.messageContent,
              sessionId: session_id,
              to_user: this.agentId,
            };
            await this.sendChat(data);
            this.$router.push(`/user/chats`);
          }
        }
      } else {
        this.$router.push(`/login?redirect=${this.$router.currentRoute.path}`);
        //this.$router.push({ name: '/login', query: { redirect: this.$router.currentRoute.path } });
      }
    },
    closeMessage() {
      this.dialogMessage = false;
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
#agents {
  color: #4a425f;
  background-color: #f7f7f7;
}

@media (min-width: 960px) {
  #left-col {
    width: 75%;
  }
}

@media (min-width: 960px) {
  #right-col {
    width: 25%;
  }
}
</style>
