<template>
  <div class="container py-5 mt-5">
    <!-- Header Section -->
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div style="margin-top: 55px;">
        <h2 class="fw-bold new-head">Newly Listed Properties</h2>
        <span class="underline"></span>
        <p class="text-muted new-head-sm">With over 1 Million+ Homes for sale available on the ProptyFinder</p>
      </div>

      <!-- View All & Navigation Buttons -->
      <div class="d-flex align-items-center">
        <button class="btn btn-outline-primary rounded-pill px-4 me-3">View All</button><br>
        <button class="carousel-btn left me-2" @click="scrollLeft1">
          <i class="fas fa-chevron-left btn-danger"></i>
        </button>
        <button class="carousel-btn right" @click="scrollRight1">
          <i class="fas fa-chevron-right btn-danger"></i>
        </button>
      </div>
    </div>

    <!-- Property Cards Section -->
    <div class="position-relative">
      <div class="row flex-nowrap overflow-auto">
        <div class="col-md-3 col-sm-6" v-for="product in products" :key="product.id">
          <div class="card property-card border-0 shadow-sm">
            <div class="position-relative">
              <img
                :src="product.image"
                class="card-img-top rounded-top"
                alt="Product Image"
                v-if="product.image"
                style="height:200px;"
                
              />
              
              <p v-if="!product.image">No image available</p>
              <!-- Product Type Badge -->
              <span class="badge product-type-badge">{{ product.product_type || "N/A" }}</span>
              <span class="badge category-badge">{{ product.category || "N/A" }}</span>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="fw-bold mb-0">  {{ formatCurrency(product.price.toLocaleString() )}}</h5>
                <button class="btn text-secondary btn-outline-primary">
                  <i class="fas fa-heart"></i>
                </button>
              </div>
              <p class="fw-semibold">{{ product.name }}</p>
              <p class="text-muted small">
                <i class="fas fa-map-marker-alt me-1"></i>
                {{ product.location }}
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <small class="text-muted">
                  <i class="fas fa-eye me-1"></i> {{ product.view_count }}
                </small>
                <span class="agent-badge">Agent</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      products: [],
    };
  },
  mounted() {
    this.fetchFeaturedProducts();
  },
  methods: {
    formatCurrency(value) {
    if (!value) return "₦0"; // Handle null or undefined values
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
    }).format(value);
  },
    async fetchFeaturedProducts() {
      try {
        const response = await axios.get(`http://127.0.0.1:8000/api/featured-product`);
        this.products = response.data.data.map((item) => ({
          id: item.id,
          name: item.title,
          price: item.new_price,
          image: item.product_images.length > 0 ? item.product_images[0].full : null,
          location: `${item.lga?.name || "Unknown"}, ${item.state?.name || "Unknown"}`,
          category: item.category_id,
          product_type: item.product_type,
          view_count: item.view_count || 0,
        }));
        console.log(response.data);

      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    scrollLeft1() {
      document.querySelector(".row.flex-nowrap").scrollBy({ left: -300, behavior: "smooth" });
    },
    scrollRight1() {
      document.querySelector(".row.flex-nowrap").scrollBy({ left: 300, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.underline {
  display: inline-block;
  width: 100px;
  height: 3px;
  background-color: orange;
  margin-left: 10px;
}

.carousel-btn {
  border: 1px solid #007bff;
  background: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 18px;
  color: #007bff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-btn:hover {
  background: #007bff;
  color: white;
}

.product-type-badge {
  position: absolute;
  top: 10px;
  left: 0px;
  background-color: #0047ab;
  color: white;
  padding: 5px 10px;
  border-radius: 0 15px 15px 0;
  font-size: 14px;
  font-weight: bold;
}

.favorite-btn {
  background-color: #0047ab;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agent-badge{
  color: #008000;
}
.new-head{
  font-family: Inter;
  font-weight: 600;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
  color:#1E1C23;

}

.new-head-sm
 {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;

 }

 .position-relative {
  overflow: hidden; /* Hides both horizontal and vertical scrollbars */
}

.row.flex-nowrap {
  overflow-x: auto;
  overflow-y: hidden; /* Ensures no vertical scrollbar */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  -ms-overflow-style: none; /* Hides scrollbar in IE/Edge */
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.row.flex-nowrap::-webkit-scrollbar {
  display: none;
}



</style>
