import { render, staticRenderFns } from "./ClientReview.vue?vue&type=template&id=28d817fe&scoped=true"
import script from "./ClientReview.vue?vue&type=script&lang=js"
export * from "./ClientReview.vue?vue&type=script&lang=js"
import style0 from "./ClientReview.vue?vue&type=style&index=0&id=28d817fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28d817fe",
  null
  
)

export default component.exports