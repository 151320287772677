<template>
  <div>
    <v-footer
      style="background: #02081D; color: white"
      class="footer  mb-4"
      :class="{ 'px-16': $vuetify.breakpoint.mdAndUp }"
    >
      <v-container>
        <v-row dense align="start" class="mt-4">
          <v-col cols="12" md="3">
            <img
              height="30"
              class="mt-5"
              src="../../assets/images/housefinderLogo.png"
              alt=""
            />
            <div class="me-5">
              <p class="" style="font-size: 14px; text-align: justify;">
                We are committed to ensuring home accessibilty to all. Connect with thousands of sellers across Nigeria and find the property that will suit your requirements.
              </p>
            </div>
            <div class="mt-10 d-flex">
              <a
                style="text-decoration: none"
                href="http://facebook.com/housefindertech"
                target="_blank"
                ><v-icon color="#3b5998" size="30" class="mr-4"
                  >mdi-facebook
                </v-icon></a
              >
              <a
                style="text-decoration: none"
                href="http://instagram.com/housefinder.ng"
                target="_blank"
                ><v-icon color="#C13584" size="30" class="mr-4"
                  >mdi-instagram
                </v-icon></a
              >
              <v-icon color="#08a0e9" size="30" class="mr-4"
                >mdi-twitter
              </v-icon>
            </div>
          </v-col>
          <v-col cols="6" md="2">
            <h1 class="title py-4">Account</h1>
            <p class="mt-n3">
              <router-link
                class=" text-decoration-none grey--text"
                :to="{ path: `/register` }"
              >
                Services
              </router-link>
            </p>
            <p class="mt-n3">
              <router-link
                class=" text-decoration-none grey--text"
                :to="{ path: `/login` }"
              >
                Property Listing
              </router-link>
            </p>
            <p class="mt-n3">
              <router-link
                class=" text-decoration-none grey--text"
                :to="{ path: `/dashboard` }"
              >
                Listing
              </router-link>
            </p>
            <p class="mt-n3">
              <router-link
                class=" text-decoration-none grey--text"
                :to="{ path: `/dashboard` }"
              >
                Leasing and Purchase
              </router-link>
            </p>
            <p class="mt-n3">
              <router-link
                class=" text-decoration-none grey--text"
                :to="{ path: `/dashboard` }"
              >
                Agents
              </router-link>
            </p>
          </v-col>
          <v-col cols="6" md="2">
            <h1 class="title py-4">About</h1>
            <p class="mt-n3">
              <router-link
                class=" text-decoration-none grey--text"
                :to="{ path: `/` }"
              >
                Privacy Policy
              </router-link>
            </p>
            <p class="mt-n3">
              <router-link
                class=" text-decoration-none grey--text"
                :to="{ path: `/about` }"
              >
                Terms & conditions
              </router-link>
            </p>
            <p class="mt-n3">
              <router-link
                class=" text-decoration-none grey--text"
                :to="{ path: `/agents` }"
              >
                Contact us
              </router-link>
            </p>
            <p class="mt-n3">
              <router-link
                class=" text-decoration-none grey--text"
                :to="{ path: `/contact` }"
              >
                About 
              </router-link>
            </p>
          </v-col>
          <v-col cols="6" md="3">
            <h1 class="title py-4">Contact Us</h1>
            <p class="mt-n2 text-subtitle-2">
              No. 35 Ajose Adeogun street, Utako District Abuja, Nigeria
            </p>
            <p class="mt-n2 text-subtitle-2">+2348169416658</p>
            <p class="mt-n2 text-subtitle-2">info@housefinder.com.ng</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <div class=" py-2 mt-n2">
          <div class="text-center">
            <div class="" style="font-size:14px">
              ©
              {{
                ` ${new Date().getFullYear()} | Housefinder all right reserved`
              }}
            </div>
          </div>
        </div>
      </v-container>
    </v-footer>
  </div>
</template>
<script>
export default {};
</script>
<style>
.link {
  color: #fff8f8;
}
</style>
