import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import VueToastr from "vue-toastr";
import VueTruncate from "vue-truncate-filter";
import Meta from "vue-meta";
import VueChatScroll from "vue-chat-scroll";

// FontAwesome Imports
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons"; // Solid icons
import { far } from "@fortawesome/free-regular-svg-icons"; // Regular icons
import { fab } from "@fortawesome/free-brands-svg-icons"; // Brand icons
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Add all FontAwesome icons to the library
library.add(fas, far, fab);

// Ensure FontAwesome processes <i> tags in HTML
dom.watch();

// Register FontAwesome globally
Vue.component("font-awesome-icon", FontAwesomeIcon);

// Import Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

Vue.use(VueTruncate);
Vue.use(Meta);
Vue.use(VueChatScroll);
Vue.use(VueToastr);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
