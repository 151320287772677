<template>
    
  <div class="image-right">
    <v-container class="mt-10 position-relative">
      <!-- Section Title -->
      <div class="text-center mb-10">
        <p class="text-uppercase text-grey-darken-1">CLIENT'S REVIEWS</p>
        <h1 class="font-weight-bold">We Build With The Trust of Our</h1>
        <h1 class="font-weight-bold">Clients and Agents</h1>
      </div>

  <!-- Carousel -->
  <v-carousel
    hide-delimiters
    cycle
    interval="5000"
    show-arrows="hover"
    class="testimonial-carousel"
  >

    <v-carousel-item v-for="(chunk, index) in reviewChunks" :key="index">
      <v-row justify="center">
  <v-col v-for="(review, i) in chunk" :key="i" cols="12">
    <v-card
      class="mx-auto px-5 pt-5 see"
      elevation="0"
      min-height="350"
      max-width="400"
      style="border: 1px solid #E0E0E0"
    >
      <!-- Star Rating -->
      <div class="mb-3">
        <v-icon v-for="n in 5" :key="n" color="orange" size="20" class="mr-1">mdi-star</v-icon>
      </div>

      <p class="text-md-body-1 mb-3">
          "{{ review.text }}"
        </p>

      <!-- Reviewer Info -->
      <v-card-actions class="justify">
        <v-avatar size="50">
          <v-img :src="review.image" alt="Reviewer"></v-img>
        </v-avatar>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{ review.author }}</v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold">{{ review.author }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-col>
</v-row>

    </v-carousel-item>
  </v-carousel>

  <!-- Pagination Dots -->
  <div class="d-flex justify-center mt-4">
    <span
      v-for="(dot, i) in reviewChunks.length"
      :key="i"
      @click="currentIndex = i"
      class="mx-1 w-3 h-3 rounded-full bg-grey-lighten-1 cursor-pointer"
      :class="{ 'bg-blue': i === currentIndex }"
    ></span>
  </div>
</v-container>
</div>
</template>




<script>

export default {
  data() {
    return {
      currentIndex: 0,
      reviews: [
        { text: "It was an amazing house that I got with my husband. The intuitive interface and smart recommendations helped me find the perfect home in no time. The website is designed to cater to both buyers and renters, making it accessible to everyone. It’s a great tool for anyone looking for real estate options. ", author: "Nathan Segilola.", image: "/clients/cl1.png", position: "MD at Skimin Therapy" },
        { text: "The Website is versatile and I'm loving it. The house is exaclty what we have been looking for in a decades. Thank you ProptyFinder. The website is designed to cater to both buyers and renters, making it accessible to everyone. It’s a great tool for anyone looking for real estate options.", author: "Oghenefejiro", image: "/clients/cl2.png" },
        { text: "Finding my dream home has never been this easy and stress-free. The platform is well-organized, and I was able to explore multiple properties effortlessly. The search filters helped me narrow down my options quickly. I highly recommend Housefinder to anyone looking for a smooth home-buying experience.", author: "Chukwuke Martins", image: "/clients/cl3.png", position: "MD Oshenik" },
        { text: "The platform connects buyers with trusted agents seamlessly, making property transactions more transparent. I was able to communicate with agents directly and get all my questions answered without hassle. The detailed property descriptions and images provided me with the confidence to make informed decisions. Housefinder truly simplifies the home-searching process.", author: "Amaka Uche", image: "/clients/cl1.png", position: "GMD Tesly" },
        { text: "Housefinder makes property hunting effortless and secure, ensuring that all listings are verified and legitimate. The intuitive interface and smart recommendations helped me find the perfect home in no time. I love the fact that I could explore properties across different locations without unnecessary complications. This platform has redefined the way I search for real estate.", author: "Tunde Olumide", image: "/clients/cl3.png", position: "MD at Skimin Therapy" },
        { text: "A user-friendly experience with reliable property listings that are always up to date. I appreciate how easy it is to browse through different categories and compare properties within my budget. The website is designed to cater to both buyers and renters, making it accessible to everyone. It’s a great tool for anyone looking for real estate options", author: "Blessing Okoro", image: "/clients/cl2.png", position: "HR, Temple Ltd" }
      ]
    };
  },
  computed: {
    reviewChunks() {
      return this.chunkArray(this.reviews, 1);
    }
  },

  methods: {
    chunkArray(arr, size) {
      return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
      );
    }
  },
};
</script>

<style scoped>

.image-right{
  background-image: url('/clients/review.png');
  background-position: right;

  background-repeat: no-repeat;

  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
}

.see {
  border: 1px solid #E0E0E0;
  background-color: rgba(255, 255, 255, 0.4); /* More transparent */
  color:black; /* Ensures text remains readable */
}
</style>