<template>
  <div class="container py-5">
    <!-- Header Section -->
    <div class="d-flex justify-content-between align-items-center mb-4 mt-5">
      <div>
        <p style="font-size: 16px;" class="text-uppercase text-muted">Client's Reviews</p>
        <p class="fw-bold" style="font-size: 35px;">Do You Want To Be <br>An Agent?</p>
      </div>
      <button class="btn btn-primary d-flex align-items-center">
        Become Agent <i class="fas fa-arrow-right ms-2"></i>
      </button>
    </div>

    <!-- Agents Section -->
    <div class="row">
      <div v-for="(agent, index) in agents" :key="index" class="col-md-4 mb-4">
        <div class="card shadow-sm border-0">
          <img :src="agent.image" alt="Agent Image" class="card-img-top img-size">
          <div class="card-body text-center">
            <h5 class="fw-bold">{{ agent.name }}</h5>
            <p class="text-muted">Real Estate Agent</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agents: [
        { name: 'Grace Obianuju', image: '/clients/cl1.png' },
        { name: 'Michael Babtunde', image: '/clients/cl2.png' },
        { name: 'Obinna Okafor', image: '/clients/cl3.png' },
      ],
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
}
.card {
  border-radius: 12px;
  overflow: hidden;
}
.card-body {
  padding: 20px;
}
button.btn-primary {
  background: linear-gradient(90deg, #4f46e5, #3b82f6);
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
}
button.btn-primary i {
  margin-left: 8px;
}
.img-size{
  width:auto;
  height:500px;
}
</style>
