<template>
	<v-container>
	
		<div class="text-caption mb-8 font-weight-bold text-capitalize ">
			<span>
			Property for Sale/Rent
			</span>
			<v-icon class="mt-n1" small>mdi-chevron-right</v-icon>
			<span>
				{{this.$route.params.slug}}
			</span>
		</div>
		<div class=" d-none d-md-block" style="" >
			<search-filter/>
		</div>
		<v-row>
  <!-- Main Product List Column -->
  <v-col md="8">
    <category-controls />
    <mobile-filter />

    <!-- Skeleton Loader -->
    <div v-if="skeloader || !processedProducts">
      <skeleton-loader />
    </div>

    <!-- Product List -->
    <div v-else>
      <v-row
        v-for="product in processedProducts"
        :key="product.id"
        class="rounded-lg mb-1"
        style="border: 1px solid #E0E0E0"
      >
        <!-- Product Image -->
        <v-col cols="12" sm="5">
          <router-link
            class="text-decoration-none black--text"
            :to="{ path: `/product-details/${product.slug}` }"
          >
            <v-img
              height="230px"
              :src="
                product.product_images.length > 0
                  ? product.product_images[0].full
                  : '/default-image.jpg'
              "
              lazy-src="/default-image.jpg"
              contain
            />
          </router-link>
        </v-col>

        <!-- Product Details -->
        <v-col cols="12" sm="7" class="mb-4">
          <router-link
            class="text-decoration-none black--text"
            :to="{ path: `/product-details/${product.slug}` }"
          >
            <div class="mt-n4 mt-sm-3">
              <div class="cyan--text text--darken-4 font-weight-bold">
                ₦ {{ product.converted_price }}
                <span
                  class="px-1 text-caption font-weight-normal float-right lime darken-3 white--text"
                >
                  {{ product.product_type }}
                </span>
              </div>
              <span class="text-caption">{{ product.duration }}</span>
            </div>

            <v-divider class="divider-color" width="50"></v-divider>

            <div style="height: 100px">
              <h3 class="mt-2 mb-4 grey--text text--darken-3">
                {{ product.title | truncate(65) }}
              </h3>
              <v-icon small>mdi-map-marker</v-icon>
              <span class="text-subtitle-2 grey--text text-lighten-2">
                {{ `${product.state.name}, ${product.lga.name}` }}
              </span>
              <div>
                <span class="text-caption">{{ product.listed_in }}</span>
                <v-icon v-if="product.bathroom" class="ml-4" small>
                  mdi-shower
                </v-icon>
                <span v-if="product.bathroom" class="text-caption ml-1">
                  {{ product.bathroom }}
                </span>
                <v-icon v-if="product.bedroom" class="ml-4" small>
                  mdi-bed
                </v-icon>
                <span v-if="product.bedroom" class="text-caption ml-1">
                  {{ product.bedroom }}
                </span>
              </div>
            </div>
          </router-link>

          <!-- Agent Details -->
          <router-link
            class="text-decoration-none black--text"
            :to="{ path: `/agent/products/${product.user.id}` }"
          >
            <div class="text-subtitle-2 mt-10 mb-n4 text-capitalize">
              Agent:
              <span class="lime--text text--darken-4 ml-2">
                {{
                  product.user.user_type === "admin_can_add"
                    ? "Housefinder"
                    : product.user.agent?.title
                    ? product.user.agent.title
                    : product.user.full_name
                }}
              </span>
              <v-icon
                v-if="product.user.verify?.status === 1"
                small
                class="green--text"
              >
                mdi-check-decagram
              </v-icon>
            </div>
          </router-link>

          <!-- Developer Info -->
          <div v-if="product.developer" class="mt-4 text-caption text-capitalize">
            Developer:
            <span class="grey--text text-lighten-2"> {{ product.developer }}</span>
          </div>
        </v-col>
      </v-row>

      <!-- Pagination Control -->
      <page-control />

      <!-- No Products Found Message -->
      <v-container v-if="productListLoading">
        <v-sheet class="pa-12" color="grey lighten-3">
          <v-sheet class="mx-auto text-center" color="grey lighten-3">
            <div>No Products Found</div>
          </v-sheet>
        </v-sheet>
      </v-container>
    </div>
  </v-col>

  <!-- Sidebar Filter for Desktop -->
  <v-col md="4" class="d-none d-md-block">
    <sidebar-filter />
  </v-col>
</v-row>

	
		
	
	<!--	<vueper-slides class="red mt-16">
			<vueper-slide v-for="i in 5" :key="i" :title="i.toString()" >
				ggg
			</vueper-slide>
		</vueper-slides> -->
  </v-container>
</template>

<script>
import CategoryControls from "../site/CategoryControls";
import SidebarFilter from "../layout/SidebarFilter";
import SkeletonLoader from "../layout/SkeletonLoader";
import PageControl from "../layout/PageControl";
import SearchFilter from "../layout/SearchFilter";
import MobileFilter from "../layout/MobileFilter";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
export default {
	components: {
		SidebarFilter,
		MobileFilter,
		PageControl,
		CategoryControls,
		SearchFilter,
		SkeletonLoader,
	},
	metaInfo() {
		return {
			title: `${this.$route.params.slug}, Houses, Lands & Apartments For Rent and Sale in Nigeria  | Housefinder.com.ng`,
			meta: [
				{
					vmid: "description",
					name: "description",
					content: `List of Flats for Sale in Nigeria, Real Estate Property Houses Apartments For Rent! best deals in Nigeria - Housefinder.com.ng`
				}
			]
		}
	},
	data() {
		return {
			page: 1,
			loading: true,
			tab: null,
			sort: "Sort By",
		};
	},
	computed: {
		...mapState(["pages", "productListLoading", "skeloader"]),
		...mapGetters(["processedProducts"]),
	},
	watch: {
		$route(to, from) {
			if (to.params.slug !== from.params.slug) {
				this.getCategoryProduct();
			}
		},
	},
	methods: {
		...mapActions(["getProductList", "getCategories"]),
		...mapMutations([
			"_setCurrentCategory",
			"clearFilter",
			"_setSort",
			"_setPriceRange",
			"_setListing",
			"_setPropertyType",
			"_setSkeloader",
			"_setSearchWord",
		]),
		async getData() {
			this._setCurrentCategory(this.$route.params.slug);
			if (this.$route.query.sort) {
				this._setSort(this.$route.query.sort);
			}
			if (this.$route.query.priceRange) {
				this._setPriceRange(this.$route.query.priceRange);
			}
			if (this.$route.query.listed) {
				this._setListing(this.$route.query.listed);
			}
			if (this.$route.query.propertyType) {
				this._setPropertyType(this.$route.query.propertyType);
			}
			if (this.$route.query.query) {
				this._setSearchWord(this.$route.query.query);
			}
			await this.getProductList();
			await this.getCategories();
			this._setSkeloader(false);
			this.loading = false;
		},
		async getCategoryProduct() {
			this._setSkeloader(true);
			this.clearFilter();
			this._setCurrentCategory(this.$route.params.slug);
			await this.getProductList();
			this._setSkeloader(false);
		},
	},
	created() {
		this.getData();
	},
};
</script>
